import $ from 'helper/jquery';
import Rails from '@rails/ujs';
import Foundation from 'foundation-sites';
import '@fortawesome/fontawesome-free/css/all';
import { breakpoint, pageUrl } from 'helper/clientDetails';

const images = require.context('../images', true);
// eslint-disable-next-line no-unused-vars
const imagePath = (name) => images(name, true);

Foundation.addToJquery($);
$(document).foundation();

Rails.start();

window.addEventListener('load', () => {
  // Set user's timezone as cookie to fallback detect if not set in settings
  const date = new Date();
  date.setTime(date.getTime() + (365 * 24 * 60 * 60 * 1000)); // 365d
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `browser.timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}; ${expires}; path=/; SameSite=LAX`;
});

// Collect generic client details
function collectClientDetails() {
  const screenSize = breakpoint(window.screen.width);
  const windowSize = breakpoint(window.innerWidth);

  // eslint-disable-next-line no-undef
  plausible('pageview', { u: pageUrl(), props: { screen_size: screenSize, window_size: windowSize } });
}

window.plausible = window.plausible || function (...args) {
  (window.plausible.q = window.plausible.q || []).push(args);
};
document.addEventListener('DOMContentLoaded', collectClientDetails);
